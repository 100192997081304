
const linkResolver = (doc) => {
  if (doc.type === 'article') {
    return `/stories/${doc.uid}`
  }
  if (doc.type === 'category') {
    return `/${doc.uid}`
  }
  return '/'
}

module.exports = linkResolver


/*
var linkResolver = function (doc) {
  // URL for a category type

  

  // URL for a product type
  if (doc.type === 'article') {
    return '/' + doc.uid
  }

  // Backup for all other types
  return '/'
}

module.exports.linkResolver = linkResolver
*/