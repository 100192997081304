import React from 'react'
import MultiCarousel from 'react-multi-carousel'
import Wrapper from './CarouselStyles'

const Carousel = ({ children, responsive, blog, showDots, partialVisible }) => {
  return (
    <Wrapper className={blog ? 'blog carousel-wrapper' : 'carousel-wrapper'}>
      <MultiCarousel
        showDots={!showDots}
        responsive={responsive}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        transitionDuration={900}
        containerClass="carousel-container"
        arrows={true}
        swipeable={true}
        partialVisible={partialVisible || false}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding"
      >
        {children}
      </MultiCarousel>
    </Wrapper>
  )
}

export default Carousel
