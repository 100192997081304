import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  &.blog {
    max-width: 800px;
    margin: 0 auto;
  }
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition;
  }
  .react-multiple-carousel__arrow {
    position: absolute;
    width: 60px;
    height: 70px;
    border: none;
    color: transparent;
    background: transparent;
    cursor: pointer;
    z-index: 150;
    &:focus {
      outline: none !important;
    }
    @media (max-width: ${({theme}) => theme.breakpoints.s}) {
      display: none;
    }
  }
  
  .react-multiple-carousel__arrow {
    ::before {
      width: 24px;
      height: 24px;
      z-index: 5;
      border: solid #adc1cc;
      border-width: 0 5px 5px 0;
      display: inline-block;
      padding: 3px;
      content: "";
    }
  }
  .react-multiple-carousel__arrow--left {
    left: -3%;
    ::before {
      transform: rotate(135deg);
    }
    @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
      left: -2.5%;
    }
    @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
      left: -1.1%;
    }
  }
  .react-multiple-carousel__arrow--right {
    right: -3%;
    ::before {
      transform: rotate(-45deg);
    }
    @media (min-width: ${({theme}) => theme.breakpoints.xxs}) {
      right: -2.5%;
    }
    @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
      right: -1.1%;
    }
  }  
  
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .react-multi-carousel-dot button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background .5s;
    border: none;
    background-color: ${({ theme }) => theme.colors.blueGrey};
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;
  }
  .react-multi-carousel-dot button:hover:active {
    background: ${({ theme }) => theme.colors.whyOrange};
  }
  .react-multi-carousel-dot--active button {
    background: ${({ theme }) => theme.colors.whyOrange};
  }
  .react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-multi-carousel-item {
      flex-shrink: 0 !important;
    }
    .react-multi-carousel-track {
      overflow: visible !important;
    }
  }
`

export default Wrapper